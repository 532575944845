import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { UserEcomSettings } from 'common-types';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserEcomSettingsApi {
  private apiUrl = environment.user_rest_api_server_url;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private httpClient: HttpClient) {}

  public async apiGetEcomSettingsForUser(): Promise<
    UserEcomSettings | undefined
  > {
    const apiUrl = `${this.apiUrl}/getUserEcomSettings`;

    try {
      return await firstValueFrom(
        this.httpClient
          .get<UserEcomSettings>(apiUrl, { ...requireAppCheck })
          .pipe(catchError(this.handleError)),
      );
    } catch (error) {
      console.log('Got an error in apiGetEcomSettingsForUser');
      console.log(error);
      return undefined;
    }
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
}
