import { Injectable, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthService } from '@jfw-library/ecommerce/core';
import { UserEcomSettings } from 'common-types';
import {
  Observable,
  Subject,
  catchError,
  from,
  merge,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { UserEcomSettingsApi } from './user-ecom-settings-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserEcomSettingsService {
  private authService = inject(AuthService);

  constructor(private userEcomSettingsApi: UserEcomSettingsApi) {}

  private getUserEcomSettingsSubject = new Subject<User | null>();

  private _currentUserEcomSettingsCache$:
    | Observable<UserEcomSettings | undefined>
    | undefined;

  get currentUserEcomSettings$() {
    if (!this._currentUserEcomSettingsCache$) {
      this._currentUserEcomSettingsCache$ = merge(
        this.authService.authState$,
        this.getUserEcomSettingsSubject,
      ).pipe(
        switchMap((user) =>
          !!user && !!user?.email && !!user?.uid
            ? from(this.getUserEcomSettings()).pipe(
                catchError((error) => {
                  console.log('Error getting dealerUser:', error);
                  return of(undefined);
                }),
              )
            : of(undefined),
        ),
        tap((userEcomSettings) =>
          console.log(
            'UserEcomSettings received from server:',
            userEcomSettings,
          ),
        ),
        shareReplay(1),
      ); // => gets value from the user
    }
    return this._currentUserEcomSettingsCache$;
  }

  public async refreshUserEcomSettings(user: User | null) {
    this.getUserEcomSettingsSubject.next(user);
  }

  public async getUserEcomSettings(): Promise<UserEcomSettings | undefined> {
    return await this.userEcomSettingsApi.apiGetEcomSettingsForUser();
  }

}
