import { Inject, inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '@jfw-library/ecommerce/shared';
import { EcommerceMainEnvironment } from 'common-types';

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private canonicalService = inject(CanonicalService);
  private titleService = inject(Title);
  private metaTagService = inject(Meta);

  constructor(
    @Inject('environment')
    private readonly environment: EcommerceMainEnvironment,
  ) {}

  // Updates or adds the new tag
  public setMetaTags(metaInfo: MetaInfo) {
    const { metaTitle, metaDescription, metaKeywords } = metaInfo;
    const metaDescriptionTag = this.metaTagService.getTag('name="description"');
    const metaKeywordsTag = this.metaTagService.getTag('name="keywords"');

    // Home Page
    if (metaTitle === `Jim's Formal Wear`) {
      this.titleService.setTitle(
        `Jim’s Formal Wear | The Premier Tuxedo and Suit Provider`,
      );
    } else if (metaTitle) {
      const jimsTitle = `${metaTitle} | Jim's Formal Wear`;
      this.titleService.setTitle(jimsTitle);
    } else {
      // Fall back homepage?
      this.titleService.setTitle(
        `Jim’s Formal Wear | The Premier Tuxedo and Suit Provider`,
      );
    }

    if (metaDescription) {
      if (metaDescriptionTag !== null) {
        this.metaTagService.updateTag({
          name: 'description',
          content: metaDescription,
        });
      } else {
        this.metaTagService.addTag({
          name: 'description',
          content: metaDescription,
        });
      }
    } else {
      // Remove the tag if not necessary
      this.metaTagService.removeTag('name="description"');
    }

    if (metaKeywords) {
      if (metaKeywordsTag === null) {
        this.metaTagService.addTag({
          name: 'keywords',
          content: metaKeywords,
        });
      } else {
        this.metaTagService.updateTag({
          name: 'keywords',
          content: metaKeywords,
        });
      }
    } else {
      // Remove the tag if not necessary
      if (metaKeywordsTag !== null) {
        this.metaTagService.removeTag('name="keywords"');
      }
    }
  }

  public setNoIndexTag(required: boolean = true) {
    const robotsTag = this.metaTagService.getTag('name="robots"');
    if (robotsTag !== null && robotsTag !== undefined && required === false) {
      // We have tag but is not required
      if (this.environment.allowIndexing) {
        this.metaTagService.removeTag('name="robots"');
      }
    } else if ((robotsTag === null || robotsTag === undefined) && required) {
      // If no present tag but is required
      this.metaTagService.addTag({ name: 'robots', content: 'noindex' });
    } else {
      // Nothing needs to be changed
      return;
    }
  }
  public setCanonicalTag(path?: string, required: boolean = false) {
    if (required) {
      this.canonicalService.setCanonicalURL(path);
    } else {
      this.canonicalService.removeCanonicalUrl();
    }
  }
}
